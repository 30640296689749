import { FIREBASE_AUTH_HTTP_ERRORS } from "domain/constanst/firebase/http-errors"
import { useToast } from "../useToast";
import { TOAST_TYPE } from "domain/constanst/share/components";
import { useTranslate } from "../useTranslate";


export const useHandleFirebaseError = () => {
    const { showToast } = useToast();
    const { translate } = useTranslate();

    const showAlertByErrorsFirebaseRequest = ({code}) => {
        if (code === FIREBASE_AUTH_HTTP_ERRORS.INVALID_EMAIL) {
            showToast(TOAST_TYPE.ERROR, translate('alertsMessages.auth.invalidEmail'));
            return;
        }
        if (code === FIREBASE_AUTH_HTTP_ERRORS.INVALID_CREDENTIAL) {
            showToast(TOAST_TYPE.ERROR, translate('alertsMessages.auth.invalidCredential'));
            return;
        }
        if (code ===  FIREBASE_AUTH_HTTP_ERRORS.EMAIL_ALREAY_IN_USE) {
            showToast(TOAST_TYPE.ERROR, translate('alertsMessages.auth.emailAlreadyInUse'));
            return;
        }
        if (code === FIREBASE_AUTH_HTTP_ERRORS.WEAK_PASSWORD) {
            showToast(TOAST_TYPE.WARNING, translate('alertsMessages.auth.warkPassword'));
            return;
        }
        if (code === FIREBASE_AUTH_HTTP_ERRORS.TOO_MANY_REQUESTS) {
            showToast(TOAST_TYPE.WARNING, translate('alertsMessages.auth.tooManyRequests'));
            return;
        }
        

        showToast(TOAST_TYPE.ERROR, translate('alertsMessages.serviceUnavailable'));
    }

    return {
        showAlertByErrorsFirebaseRequest
    }
}