import { useEffect } from "react";
import { useUser } from "../useUser";
import { useNavigate } from "react-router-dom";
import { getAuthUser, getUserByUidAuth, createUser, signInUser, singOutUser } from "domain/services/authentication"

export const useAuth = () => {
  const { uid, user, addUid, addUser, removeUser, removeUid } = useUser();
  const navaigate = useNavigate();

  useEffect(() => { 
    getAuthUser(addUid);
  }, []);

  useEffect(() => {
    const getUser = async (uid) => {
      if (uid) {
        const _user = await getUserByUidAuth(uid);
        if(_user) addUser(_user);
      }
    }
    if (!user.userId) getUser(uid);
  }, [uid, user])

  const signIn = async (email, password) => {
    const { user } = await signInUser(email, password);
    addUser(user);
    navaigate('/dashboard');
  };

  const signUp = async (userInfo) => {    
    const { user } = await createUser(userInfo);
    addUser(user);
    navaigate('/dashboard');
  }

  const signOut = () => {
    singOutUser();
    removeUser();
    removeUid();
    navaigate('/authentication/sign-in');
  };

  return { uid, user, signIn, signOut, signUp };
};