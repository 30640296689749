// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftBox from "presentation/components/SoftBox";
import SoftTypography from "presentation/components/SoftTypography";

// Soft UI Dashboard React examples
import DefaultNavbar from "presentation/layouts/Navbars/DefaultNavbar";
import PageLayout from "presentation/layouts/LayoutContainers/PageLayout";

// Authentication layout components
import Footer from "presentation/scenes/LandingPage/components/Footer";
import { useTranslate } from "domain/hooks/useTranslate";
import { useAuth } from "domain/hooks/useAuth";
import { useEffect, useState } from "react";
import SoftButton from "presentation/components/SoftButton";

function LandingLayout({ color, title, description, callToAction, callToInfo, image, top, children }) {
  const { user } = useAuth();
  const { translate } = useTranslate();
  const [ navBarLinks, setNavBarLinks ] = useState([]);
  const [ footerLinks, setFooterLinks ] = useState([]);
  const [ links, setLinks ] = useState([
    {
      name: translate('services'),
      icon: 'miscellaneous_services',
      route: '#services',
      requierdAuth: false,
    },
    // {
    //   name: translate('pricing'),
    //   icon: 'price_check',
    //   route: '#pricing',
    //   requierdAuth: false,
    // },
    {
      name: translate('aboutUs'),
      icon: 'business',
      route: '#aboutUs',
      requierdAuth: false,
    },
    {
      name: translate('contactUs'),
      icon: 'connect_without_contact',
      route: '#contactUs',
      requierdAuth: false,
    },
    // {
    //   name: translate('signUp'),
    //   icon: 'person_add',
    //   route: '/authentication/sign-up',
    //   requierdAuth: false,
    // }
  ]);

  useEffect(() => {
    if (user.userId) {
      setNavBarLinks([
        ...links.filter(link => !['/authentication/sign-up'].includes(link.route))
      ]);
      setFooterLinks([
        ...links.filter(link => !['/authentication/sign-up'].includes(link.route))
      ]);
    } else {
      setNavBarLinks([...links]);
      setFooterLinks([...links]);
    }
  }, [user, links]);


  return (
    <PageLayout background="white">
      <DefaultNavbar
        action={{
          type: "internal",
          // route: user.userId ? "/profile" : "/authentication/sign-in",
          // label: translate(user.userId ? 'profile' : 'signIn'),
          route: `mailto:${translate('appWeb.contactinfo.email')}`,
          label: translate('sendEmail'),
          color: "dark",
        }}
        links={navBarLinks}
      />
      <Grid
        container
        justifyContent="center"
        sx={{
          minHeight: "55vh",
          margin: 0,
        }}
        style={{backgroundColor: '#f7f7f7'}}
      >
        <Grid item xs={12} sm={12} md={5} xl={4}>
          <SoftBox mt={top}>
            <SoftBox pt={3} px={3}>
              <SoftBox mb={1}>
                <SoftTypography variant="h1" fontSize={{xs: 30, sm: 34, md: 34, lg: 38, xl: 42}} fontWeight="bold" color={color} textGradient>
                  {title}
                </SoftTypography>
              </SoftBox>
              <SoftTypography variant="h2" fontSize={{xs: 20, sm: 22, md: 22, lg: 24, xl: 24}} fontWeight="regular" color="text">
                {description}
              </SoftTypography>
              {callToAction && callToAction.route && <SoftBox display={{ xs: "inline-block", lg: "inline-block" }} mt={3} mr={3}>
                <SoftButton
                  component="a"
                  href={callToAction.route}
                  rel="noreferrer"
                  variant="gradient"
                  color={callToAction.color ? callToAction.color : color}
                  size="small"
                  circular
                >
                  {callToAction.text}
                </SoftButton>
              </SoftBox>}

              {callToInfo && callToInfo.route && <SoftBox display={{ xs: "inline-block", lg: "inline-block" }} mt={3} mr={3}>
                <SoftButton
                  component="a"
                  href={callToInfo.route}
                  rel="noreferrer"
                  variant="gradient"
                  color={callToInfo.color ? callToInfo.color : color}
                  size="small"
                  circular
                >
                  {callToInfo.text}
                </SoftButton>
              </SoftBox>}

            </SoftBox>
          </SoftBox>
        </Grid>
        <Grid item xs={12} sm={12} md={5} xl={4}>
          <SoftBox
            height="100%"
            display={{ xs: "none", md: "block" }}
            position="relative"
            right={{ md: "-12rem", xl: "-16rem" }}
            mr={-16}
            sx={{
              transform: "skewX(-10deg)",
              overflow: "hidden",
              borderBottomLeftRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
            }}
          >
            <SoftBox
              ml={-8}
              height="100%"
              sx={{
                backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                transform: "skewX(10deg)",
              }}
            />
          </SoftBox>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="center"
        sx={{
          margin: 0,
        }}
      >
        {children}
      </Grid>
      <Footer 
        showLinks
        links={footerLinks}
      />
    </PageLayout>
  );
}

// Setting default values for the props of CoverLayout
LandingLayout.defaultProps = {
  title: "",
  description: "",
  callToAction: {
    text: '',
    route: '',
    color: '',
  },
  callToInfo: {
    text: '',
    route: '',
    color: '',
  },
  color: "info",
  top: 20,
};

// Typechecking props for the CoverLayout
LandingLayout.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  title: PropTypes.string,
  description: PropTypes.string,
  callToAction: PropTypes.object,
  callToInfo: PropTypes.object,
  image: PropTypes.string.isRequired,
  top: PropTypes.number,
  children: PropTypes.node.isRequired,
};

export default LandingLayout;
