// Soft UI Dashboard React layouts
import Dashboard from "presentation/scenes/dashboard";
import Tables from "presentation/scenes/tables";
import Billing from "presentation/scenes/billing";
import Profile from "presentation/scenes/profile";
import SignIn from "presentation/scenes/authentication/sign-in";
import SignUp from "presentation/scenes/authentication/sign-up";

// Soft UI Dashboard React icons
import Shop from "presentation/layouts/Icons/Shop";
import Office from "presentation/layouts/Icons/Office";
import Settings from "presentation/layouts/Icons/Settings";
import Document from "presentation/layouts/Icons/Document";
import SpaceShip from "presentation/layouts/Icons/SpaceShip";
import CustomerSupport from "presentation/layouts/Icons/CustomerSupport";
import CreditCard from "presentation/layouts/Icons/CreditCard";
import Cube from "presentation/layouts/Icons/Cube";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <Shop size="12px" />,
    component: <Dashboard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Tables",
    key: "tables",
    route: "/tables",
    icon: <Office size="12px" />,
    component: <Tables />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    route: "/billing",
    icon: <CreditCard size="12px" />,
    component: <Billing />,
    noCollapse: true,
  },
  { type: "title", title: "Account Pages", key: "account-pages" },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: <CustomerSupport size="12px" />,
    component: <Profile />,
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "Sign In",
  //   key: "sign-in",
  //   route: "/authentication/sign-in",
  //   icon: <Document size="12px" />,
  //   component: <SignIn />,
  //   noCollapse: true,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   route: "/authentication/sign-up",
  //   icon: <SpaceShip size="12px" />,
  //   component: <SignUp />,
  //   noCollapse: true,
  // },
];

export default routes;
