// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Soft UI Dashboard React components
import SoftBox from "presentation/components/SoftBox";
import SoftTypography from "presentation/components/SoftTypography";

// Soft UI Dashboard React layouts
import DashboardLayout from "presentation/layouts/LayoutContainers/DashboardLayout";
import Footer from "presentation/layouts/Footer";
import ProfileInfoCard from "presentation/layouts/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "presentation/layouts/Lists/ProfilesList";
import AppsList from "presentation/layouts/Lists/AppsList";
import DefaultProjectCard from "presentation/layouts/Cards/ProjectCards/DefaultProjectCard";
import PlaceholderCard from "presentation/layouts/Cards/PlaceholderCard";

// Overview page components
import Header from "presentation/scenes/profile/components/Header";
import PlatformSettings from "presentation/scenes/profile/components/PlatformSettings";

// Data
import profilesListData from "presentation/scenes/profile/data/profilesListData";
import appsListData from "presentation/scenes/profile/data/appsListData";

// Images
import homeDecor1 from "presentation/assets/images/home-decor-1.jpg";
import homeDecor2 from "presentation/assets/images/home-decor-2.jpg";
import homeDecor3 from "presentation/assets/images/home-decor-3.jpg";
import team1 from "presentation/assets/images/team-1.jpg";
import team2 from "presentation/assets/images/team-2.jpg";
import team3 from "presentation/assets/images/team-3.jpg";
import team4 from "presentation/assets/images/team-4.jpg";
import { useTranslate } from "domain/hooks/useTranslate";
import { useAuth } from "domain/hooks/useAuth";
import { useEffect, useState } from "react";

const SocialIcons = {
  'FacebookIcon': <FacebookIcon />,
  'TwitterIcon': <TwitterIcon />,
  'InstagramIcon': <InstagramIcon />
}

function Overview() {
  const { translate } = useTranslate();
  const { user } = useAuth();
  
  return (
    <DashboardLayout>
      <Header />
      <SoftBox mt={5} mb={3}>
        <Grid container spacing={3}>
          {/* <Grid item xs={12} md={6} xl={4}>
            <PlatformSettings />
          </Grid> */}
          <Grid item xs={12} xl={4}>
            <ProfileInfoCard
              title={translate('profileInformation')}
              description={user.info}
              info={[
                {
                  label: translate('fullName'),
                  value: user.fullName
                },
                {
                  label: translate('mobile'),
                  value: user.movil
                },
                {
                  label: translate('email'),
                  value: user.email
                },
                {
                  label: translate('location'),
                  value: user.location
                }
              ]}
              socialInfo = {{
                label: translate('social'),
                social: !user ? [] : user.social.map(({link, icon, color}) => ({
                  link,
                  icon: SocialIcons[icon],
                  color
                }))
              }}
              action={{ route: "", tooltip: translate('editProfile') }}
            />
          </Grid>
          {/* <Grid item xs={12} xl={4}>
            <ProfilesList title="conversations" profiles={profilesListData} />
          </Grid> */}
          <Grid item xs={12} xl={4}>
            <AppsList title={translate('apps')} apps={appsListData} />
          </Grid>
        </Grid>
      </SoftBox>
      {/* <SoftBox mb={3}>
        <Card>
          <SoftBox pt={2} px={2}>
            <SoftBox mb={0.5}>
              <SoftTypography variant="h6" fontWeight="medium">
                Projects
              </SoftTypography>
            </SoftBox>
            <SoftBox mb={1}>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                Architects design houses
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <SoftBox p={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} xl={3}>
                <DefaultProjectCard
                  image={homeDecor1}
                  label="project #2"
                  title="modern"
                  description="As Uber works through a huge amount of internal management turmoil."
                  action={{
                    type: "internal",
                    route: "/pages/profile/profile-overview",
                    color: "info",
                    label: "view project",
                  }}
                  authors={[
                    { image: team1, name: "Elena Morison" },
                    { image: team2, name: "Ryan Milly" },
                    { image: team3, name: "Nick Daniel" },
                    { image: team4, name: "Peterson" },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6} xl={3}>
                <DefaultProjectCard
                  image={homeDecor2}
                  label="project #1"
                  title="scandinavian"
                  description="Music is something that every person has his or her own specific opinion about."
                  action={{
                    type: "internal",
                    route: "/pages/profile/profile-overview",
                    color: "info",
                    label: "view project",
                  }}
                  authors={[
                    { image: team3, name: "Nick Daniel" },
                    { image: team4, name: "Peterson" },
                    { image: team1, name: "Elena Morison" },
                    { image: team2, name: "Ryan Milly" },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6} xl={3}>
                <DefaultProjectCard
                  image={homeDecor3}
                  label="project #3"
                  title="minimalist"
                  description="Different people have different taste, and various types of music."
                  action={{
                    type: "internal",
                    route: "/pages/profile/profile-overview",
                    color: "info",
                    label: "view project",
                  }}
                  authors={[
                    { image: team4, name: "Peterson" },
                    { image: team3, name: "Nick Daniel" },
                    { image: team2, name: "Ryan Milly" },
                    { image: team1, name: "Elena Morison" },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6} xl={3}>
                <PlaceholderCard title={{ variant: "h5", text: "New project" }} outlined />
              </Grid>
            </Grid>
          </SoftBox>
        </Card>
      </SoftBox> */}

      <Footer
        company={{href: translate('appWeb.site'), name: translate('appWeb.title')}}
        links = {[
          { href: translate('appWeb.site'), name: translate('appWeb.title') },
          { href: `${translate('appWeb.site')}presentation`, name: translate('aboutUs') },
          { href: `${translate('appWeb.site')}blog`, name: translate('blog')},
          { href: `${translate('appWeb.site')}license`, name: translate('license') },
        ]}
      />
    </DashboardLayout>
  );
}

export default Overview;
