import { useTranslation } from 'react-i18next'

export const useTranslate = () => {
    const { t, i18n } =  useTranslation();

    const changeLanguague = (lang) => {
        i18n.changeLanguage(lang);
    }

    return {
        translate: t,
        changeLanguague
    }
}