// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import { Alert, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useToast } from "domain/hooks/useToast";

const Toast = ({ open, type, message, onClose }) => {
    const [ openAlert, setOpenAlert ] = useState(open);
    const { hideToast } = useToast();

    const handleClose = () => {
        onClose && onClose();
        hideToast();
    }

    useEffect(() => {
        setOpenAlert(open);
    }, [open])

    if(!type) return;

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={openAlert}
            autoHideDuration={6000}
            onClose={handleClose}
        >
            <Alert
                onClose={handleClose}
                severity={type}
                variant="filled"
                sx={{ width: '100%' }}
            >
                {message}
            </Alert>
        </Snackbar>
    )
}

// Setting default values for the props of BasicLayout
Toast.defaultProps = {
    open: false,
    message: "",
    type: "",
    onClose: () => {}
};

// Typechecking props for the BasicLayout
Toast.propTypes = {
    open: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    onClose: PropTypes.func,
};

export default Toast;