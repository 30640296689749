// Images
import fincloudtracker from "presentation/assets/images/apps/fincloudtracker.png";

const appsListData = [
  {
    image: fincloudtracker,
    name: "FinCloud Tracker",
    description: "Aplicación de gestión financiera personal basada en la nube.",
    action: {
      type: "internal",
      route: "#",
      color: "info",
      label: "Próximamente",
    },
  },
];

export default appsListData;
