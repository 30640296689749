import { createContext, useContext, useReducer, useMemo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import Toast from "presentation/share/components/Toast";

const Share = createContext(null);
Share.displayName = 'ShareContext';

const reducer = (state, action) => {
    switch (action.type) {
        case "SHARE_TOAST": {
            return { ...state, toast: action.value };
        }
    }
}

const ShareControllerProvider = ({children}) => {
    const initialState = {
        toast: {
            type: '',
            message: '',
            open: false,
        }
    }
    const [shareController, shareDispatch] = useReducer(reducer, initialState);

    const value = useMemo(() => [shareController, shareDispatch], [shareController, shareDispatch]);

    return <Share.Provider value={value}>
        <Toast 
            open={shareController.toast.open}
            type={shareController.toast.type}
            message={shareController.toast.message}
        />    
        {children}
    </Share.Provider>;
}

const useShareController = () => {
    const context = useContext(Share);
  
    if (!context) {
      throw new Error("useShareController should be used inside the ShareControllerProvider.");
    }
  
    return context;
}

// Typechecking props for the SoftUIControllerProvider
ShareControllerProvider.propTypes = {
    children: PropTypes.node.isRequired,
};


const setShareToast = (dispatch, value) => dispatch({ type: "SHARE_TOAST", value });

export {
    ShareControllerProvider,
    useShareController,
    setShareToast,
}
