import { initialUser, useAuthController, setAuthUid, setAuthUser} from "data/context/authContext";

export const useUser = () => {
  const [authController, authDispatch] = useAuthController();
  const { uid, user } = authController;

  const addUid = (user) => {
    setAuthUid(authDispatch, user?.uid);
  };

  const removeUid = () => {
    setAuthUid(authDispatch, null);
  };

  const addUser = (user) => {
    setAuthUser(authDispatch, user);
  };

  const removeUser = () => {
    setAuthUser(authDispatch, initialUser);
  };

  return { uid, user, addUid, removeUid, addUser, removeUser };
};
