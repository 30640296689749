import { useState } from "react";
import { useTranslate } from "domain/hooks/useTranslate";
import { Grid } from "@mui/material";

// Images
import developerCompuert from 'presentation/assets/images/landing-page/site/developer-compueter.svg';
import compuertSoftware from 'presentation/assets/images/landing-page/site/computer-software.svg';
import softwareBugs from 'presentation/assets/images/landing-page/site/software-bugs.jpg';
import peoplesWorks from 'presentation/assets/images/landing-page/site/peoples-works.svg';
import peoplesUpgrade from 'presentation/assets/images/landing-page/site/peoples-upgrade.svg';


import SoftBox from "presentation/components/SoftBox";
import SoftTypography from "presentation/components/SoftTypography";

import fincloudtracker from 'presentation/assets/images/apps/fincloudtracker.png';
import SoftButton from "presentation/components/SoftButton";
import SoftInput from "presentation/components/SoftInput";

import LandingLayout from "./components/LandingLayout";
import { createContactMessage } from "domain/services/page";
import { useHandleFirebaseError } from "domain/hooks/useHandleFirebaseError";
import { useToast } from "domain/hooks/useToast";
import { TOAST_TYPE } from "domain/constanst/share/components";

function LandingPage() {
    const { translate } = useTranslate();
    const { showToast } = useToast();
    const { showAlertByErrorsFirebaseRequest } = useHandleFirebaseError();
    const [ contactData, setContactData ] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });
    const [validateForms, setValidateForm] = useState({
        name: {
            isValid: true,
            isRequired: true,
        },
        email: {
            isValid: true,
            isRequired: true,
        },
        phone: {
            isValid: true,
            isRequired: false,
        },
        message: {
            isValid: true,
            isRequired: false,
        }
    });

    const [challengesList, setChallengesList] = useState([
        { label: 'Desafíos empresariales sin soluciones en la nube', icon: 'https://cdn.hugeicons.com/icons/cloud-server-stroke-rounded.svg' },
        { label: 'Ineficiencia operativa y procesos manuales', icon: 'https://cdn.hugeicons.com/icons/robotic-stroke-rounded.svg' },
        { label: 'Desafíos en la integración y silos de datos', icon: 'https://cdn.hugeicons.com/icons/git-merge-stroke-rounded.svg' },
        { label: 'Necesidad de software personalizado', icon: 'https://cdn.hugeicons.com/icons/laptop-programming-stroke-rounded.svg' },
        { label: 'Perdida frecuente de información en los procesos', icon: 'https://cdn.hugeicons.com/icons/database-sync-01-stroke-rounded.svg' },
    ]);

    const [solutionsList, setSolutionsList] = useState([
        { label: 'Desarrollo personalizado', description: 'Destacamos por nuestro compromiso con la excelencia en el desarrollo personalizado, adaptado específicamente a las necesidades únicas de cada cliente.', icon: 'https://cdn.hugeicons.com/icons/code-circle-stroke-rounded.svg' },
        { label: 'Innovación y eficiencia',  description: 'Nuestra experiencia no se limita solo a crear soluciones personalizadas, sino que también incluye el desarrollo de aplicaciones web propias.', icon: 'https://cdn.hugeicons.com/icons/ai-innovation-01-stroke-rounded.svg' },
        { label: 'Integración y automatización',  description: 'Podemos integrar tecnologías de vanguardia y automatizar procesos, brindando agilidad y eficacia necesarias para prosperar en un entorno dinámico.', icon: 'https://cdn.hugeicons.com/icons/robotic-stroke-rounded.svg' },
        { label: 'Seguridad y Confidencialidad',  description: 'Priorizamos la seguridad y confidencialidad en cada fase del desarrollo, garantizando la protección de datos sensibles y la privacidad de nuestros clientes.', icon: 'https://cdn.hugeicons.com/icons/security-check-stroke-rounded.svg' }
    ]);

    // const [tecnologiasList, setTecnologiasList] = useState([
    //     { label: 'Lenguajes de programación', description: 'Golang, C#, TypeScript, JavaScript, PHP, Python', icon: 'https://cdn.hugeicons.com/icons/source-code-square-stroke-rounded.svg' },
    //     { label: 'Herramientas backend', description: '.NetCore, ExpressJS, NestJS, Laravel, DJangoRestFramework', icon: 'https://cdn.hugeicons.com/icons/ai-computer-stroke-rounded.svg' },
    //     { label: 'Herramientas/Librerias frontend', description: 'Angular, ReactJS, ReactNative, VueJS, NextJS', icon: 'https://cdn.hugeicons.com/icons/computer-programming-01-stroke-rounded.svg' },
    //     { label: 'Bases de datos', description: 'MySQL, SQLServer, MongoDB, FireStore', icon: 'https://cdn.hugeicons.com/icons/database-stroke-rounded.svg' },
    //     { label: 'Automatización y pruebas', description: 'Selenium, k6, Web Scraping', icon: 'https://cdn.hugeicons.com/icons/robotic-stroke-rounded.svg' }
    // ]);

    const [appsList, setAppsList] = useState([
        { label: 'FindCloud Tracker', description: 'Gestiona tus finanzas con simplicidad y precisión.', icon: '📊' }
    ]);

    const handleChangeInputs = (value, key) => {
        if (validateForms[key].isRequired) validateForms[key].isValid = value.trim() !== '';
        setValidateForm({...validateForms});
        setContactData({...contactData, [key]: value});
    }

    const handlerSendMessage = async () => {
        Object.keys(validateForms).map(key => handleChangeInputs(contactData[key], key));
        if (Object.keys(validateForms).map(key => validateForms[key].isValid).includes(false)) return;

        if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(contactData.email)) {
            validateForms.email.isValid = false;
            setValidateForm({...validateForms});
        }
    
        if (Object.keys(validateForms).map(key => validateForms[key].isValid).includes(false)) return;
        try {
            await createContactMessage(contactData);
            setContactData({
                name: '',
                email: '',
                phone: '',
                message: ''
            });
            showToast(TOAST_TYPE.SUCCESS, translate('sendSuccessMessage'));
        } catch (error) {
            console.log("error", error);
            showAlertByErrorsFirebaseRequest(error);
        }
    }

    return (
        <LandingLayout
            title={translate('ladingPage.welcomePage')}
            description={translate('ladingPage.slogan')}
            image={compuertSoftware}
            callToAction={{
                text: translate('ladingPage.callToAction.text'),
                route: '#contactUs'
            }}
            // callToInfo={{
            //     text: translate('callToInfo.text'),
            //     route: '#'
            // }}
            top={13}
        >

            <Grid item xs={12} sm={12} md={12} lg={8} xl={8} px={3} pt={10}>
                <SoftTypography style={{textAlign: 'center'}} fontSize={{xs: 18, sm: 22, md: 26, lg: 30, xl: 34}} mb={2}>
                    {translate('ladingPage.challengeSection.description')}
                </SoftTypography>

                <Grid container mt={10} style={{alignItems: 'center'}}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={6} pl={5} pr={5}>
                        <SoftBox>
                            <img src={softwareBugs} style={{maxWidth: '100%', maxHeight: '100%'}} />
                        </SoftBox>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={6} >
                        { 
                            challengesList.map((challenge, key) => (
                                <Grid key={key} container pl={5} pr={5}>
                                    <SoftBox style={{display: 'flex', gap: '15px'}} mt={2}>
                                        <img src={challenge.icon} style={{color: '#000', marginBottom: '10px'}} />
                                        <SoftTypography fontSize={16} fontWeight="bold">
                                            {challenge.label}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={8} xl={8} px={3}>
                <Grid container justifyContent={'center'} pt={10} pb={5}>
                    <div id="services"></div>
                    <Grid container>
                        <Grid item justifyContent={'center'} xs={12} sm={12} md={12} xl={12}>
                            <SoftTypography style={{textAlign: 'center', textTransform: 'uppercase'}} fontSize={18} mb={2}>
                                {translate('ladingPage.solutionsSection.label')}
                            </SoftTypography>
                            <SoftTypography style={{textAlign: 'center'}} fontSize={{xs: 28, sm: 28, md: 32, lg: 36, xl: 44}} pl={{xs: 5, sm: 5, md: 5, lg: 5, xl: 30}} pr={{xs: 5, sm: 5, md: 5, lg: 5, xl: 30}}>
                                {translate('ladingPage.solutionsSection.title')}
                            </SoftTypography>
                        </Grid>
                    </Grid>
                    <Grid container style={{alignItems: 'center'}}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={6} mt={5}>
                            { 
                                solutionsList.map((solution, key) => (
                                    <Grid key={key} container>
                                        <SoftBox style={{display: 'flex', gap: '25px'}} mt={4}>
                                            <SoftBox>
                                                <img src={solution.icon} width="82" height="82" style={{color: '#fff'}} />
                                            </SoftBox>
                                            <SoftBox>
                                                <SoftTypography fontSize={20} fontWeight="bold" mb={1}>
                                                    {solution.label}
                                                </SoftTypography>
                                                <SoftTypography fontSize={16}>
                                                    {solution.description}
                                                </SoftTypography>
                                            </SoftBox>
                                        </SoftBox>
                                    </Grid>
                                ))
                            }
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={6} mt={5}>
                            <SoftBox>
                                <img src={developerCompuert} style={{maxWidth: '100%', maxHeight: '100%'}} />
                            </SoftBox>
                        </Grid>
                    </Grid>                    
                </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={8} xl={8} px={3}>
                <Grid
                    container
                    justifyContent={'center'}
                    pt={5}
                    pb={5}
                    mt={10}
                    style={{backgroundColor: '#80808008', borderRadius: '30px', overflow: 'hidden'}}
                >
                    <Grid>
                        <Grid container justifyContent={'center'}>
                            <SoftTypography style={{textAlign: 'center'}} fontSize={{xs: 28, sm: 28, md: 32, lg: 36, xl: 44}} mb={2} pl={5} pr={5}>
                                {translate('ladingPage.callToActionSection.title')}
                            </SoftTypography>
                        </Grid>

                        <Grid container justifyContent={'center'}>
                            <SoftButton
                                component="a"
                                href="#contactUs"
                                rel="noreferrer"
                                color={'dark'}
                                size="large"
                                circular
                            >
                                {translate('contactUs')}
                            </SoftButton>
                        </Grid>
                    </Grid>                    
                </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={8} xl={8} px={3}>
                <Grid container justifyContent={'center'} pt={10} pb={5}>
                    <div id="aboutUs"></div>
                    <Grid container style={{alignItems: 'center'}}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={6} mt={5}>
                            <SoftBox>
                                <img src={peoplesWorks} style={{maxWidth: '100%', maxHeight: '100%'}} />
                            </SoftBox>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={6} mt={5}>
                            <Grid item justifyContent={'center'} xs={12} sm={12} md={12} xl={12}>
                                <SoftTypography fontSize={{xs: 28, sm: 28, md: 32, lg: 36, xl: 44}} pl={{xs: 0, sm: 0, md: 0, lg: 5, xl: 5}} pr={{xs: 0, sm: 0, md: 0, lg: 5, xl: 5}}>
                                    {translate('aboutUs')}
                                </SoftTypography>
                                <SoftTypography color="text" fontWeight={'bold'} fontSize={20} pl={{xs: 0, sm: 0, md: 0, lg: 5, xl: 5}} pr={{xs: 0, sm: 0, md: 0, lg: 5, xl: 5}} mb={5}>
                                    {translate('ownerPages.name')}, {translate('ownerPages.profession')}, {translate('ownerPages.position')}.
                                </SoftTypography>

                                <SoftTypography color="text" fontSize={20} pl={{xs: 0, sm: 0, md: 0, lg: 5, xl: 5}} pr={{xs: 0, sm: 0, md: 0, lg: 5, xl: 5}}>
                                    {translate('ladingPage.aboutUsSection.description')}
                                </SoftTypography>
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid container style={{alignItems: 'center'}}>
                        
                    </Grid>                   
                </Grid>
            </Grid>            

            {/* <Grid item xs={12} sm={12} md={9} xl={8} px={3}>
                <Grid
                    container
                    justifyContent={'center'}
                    pt={5}
                    pb={5}
                >
                    <SoftTypography style={{textAlign: 'center'}} fontSize={34} mb={5} pl={15} pr={15}>
                        Nuestros Clientes, Nuestro Orgullo
                    </SoftTypography>
                    <Grid>
                        <img 
                            style={{maxWidth: '280px', filter: 'grayscale(100%)', margin: '5px 10px'}}
                            src={heLogo}
                            onMouseOver={(e) => (e.target.style.filter = 'grayscale(0%)')}
                            onMouseOut={(e) => (e.target.style.filter = 'grayscale(100%)')}
                        />
                        <img
                            style={{maxWidth: '280px', filter: 'grayscale(100%)', margin: '5px 10px'}}
                            src={ceLogo}
                            onMouseOver={(e) => (e.target.style.filter = 'grayscale(0%)')}
                            onMouseOut={(e) => (e.target.style.filter = 'grayscale(100%)')}
                        />
                    </Grid>
                </Grid>
            </Grid> */}


            
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8} px={3}>
                <Grid
                    container
                    justifyContent={'center'}
                    pt={5} pb={5}
                    mt={10}
                    // style={{backgroundColor: '#80808008', borderRadius: '30px'}}
                >
                    <div id="contactUs"></div>
                    <Grid>
                        <Grid item justifyContent={'center'} xs={12} sm={12} md={12} xl={12}>
                            <SoftTypography style={{textAlign: 'center'}} fontSize={{xs: 28, sm: 28, md: 32, lg: 36, xl: 44}} mb={2} pl={5} pr={5}>
                                {translate('ladingPage.contactSection.title')}
                            </SoftTypography>
                            <SoftTypography color="text" fontSize={14} style={{textAlign: 'center'}} pl={5} pr={5}>
                                {translate('ladingPage.contactSection.description')}
                            </SoftTypography>
                        </Grid>
                    </Grid>

                    <Grid container style={{alignItems: 'center'}}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={6} mt={5}>
                            <SoftBox component="form" role="form">
                                <SoftTypography style={{textAlign: 'center', textTransform: 'uppercase'}} fontSize={18} mb={2}>
                                    {translate('contactUs')}
                                </SoftTypography>
                                <SoftBox mb={2}>
                                    <SoftBox ml={0.5}>
                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                            {translate('name')}
                                        </SoftTypography>
                                    </SoftBox>

                                    <SoftInput type="text" placeholder={translate('name')} error={!validateForms.name.isValid} value={contactData.name} onChange={(e) => handleChangeInputs(e.target.value, 'name')} />
                                </SoftBox>
                                <SoftBox mb={2}>
                                    <SoftBox ml={0.5}>
                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                            {translate('email')}
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftInput type="text" placeholder={translate('email')} error={!validateForms.email.isValid} value={contactData.email} onChange={(e) => handleChangeInputs(e.target.value, 'email')} />
                                </SoftBox>
                                <SoftBox mb={2}>
                                    <SoftBox ml={0.5}>
                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                            {translate('phone')}
                                        </SoftTypography>
                                    </SoftBox>

                                    <SoftInput type="text" placeholder={translate('phone')} value={contactData.phone} onChange={(e) => handleChangeInputs(e.target.value, 'phone')} />
                                </SoftBox>
                                <SoftBox mb={2}>
                                    <SoftBox ml={0.5}>
                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                            {translate('message')}
                                        </SoftTypography>
                                    </SoftBox>
                                    
                                    <SoftInput 
                                        multiline
                                        rows={4}
                                        placeholder={translate('message')}
                                        value={contactData.message}
                                        onChange={(e) => handleChangeInputs(e.target.value, 'message')} />
                                </SoftBox>

                                <Grid container justifyContent="center">
                                    <SoftBox mb={2} mt={2}>
                                        <SoftButton
                                            rel="noreferrer"
                                            color={'dark'}
                                            size="large"
                                            circular
                                            onClick={handlerSendMessage}
                                        >
                                            {translate('sendMessage')}
                                        </SoftButton>
                                    </SoftBox>
                                </Grid>
                            </SoftBox>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={6} mt={5}>
                            <SoftBox>
                                <img src={peoplesUpgrade} style={{maxWidth: '100%', maxHeight: '100%'}} />
                            </SoftBox>
                        </Grid>
                    </Grid>                
                </Grid>
            </Grid>


            {/* <Grid item xs={12} sm={12} md={9} xl={8} px={3}>
                <Grid
                    container
                    justifyContent={'center'}
                    pt={5} pb={5}
                    mt={10}
                    style={{backgroundColor: '#80808008'}}
                >
                    <SoftBox>
                        <Grid container justifyContent={'center'}>
                            <SoftTypography variant="h3" style={{textAlign: 'center'}} mb={2}>
                                Nuestras Aplicaciones Web
                            </SoftTypography>
                            <SoftTypography color="text" fontSize={14} style={{textAlign: 'center'}} pl={5} pr={5}>
                                En Softindev, te invitamos a explorar nuestro conjunto de aplicaciones web que destacan por su innovación y eficiencia.
                                cada aplicación es una expresión de nuestro compromiso con la excelencia.
                                Descubre cómo nuestras aplicaciones web pueden impulsar tu negocio y simplificar tus operaciones diarias.
                            </SoftTypography>
                        </Grid>

                        <Grid
                            container
                            justifyContent={'center'}
                            mt={5}
                        >
                            { 
                                appsList.map((app, key) => (
                                    <Grid key={key} container md={4} justifyContent={'center'} pl={4} pr={4}>
                                        <SoftTypography fontSize={52}>
                                            {app.icon}
                                        </SoftTypography>
                                        <Grid md={12}>
                                            <SoftTypography fontSize={16} fontWeight="bold" style={{textAlign: 'center'}} mb={2}>
                                                {app.label}
                                            </SoftTypography>
                                        </Grid>
                                        <Grid md={12}>
                                            <SoftTypography fontSize={14} style={{textAlign: 'center'}} mb={2}>
                                                {app.description}
                                            </SoftTypography>
                                        </Grid>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </SoftBox>
                </Grid>
            </Grid> */}
            
        </LandingLayout>
    );
}

export default LandingPage;