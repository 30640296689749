import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard React components
import SoftBox from "presentation/components/SoftBox";
import SoftTypography from "presentation/components/SoftTypography";
import SoftInput from "presentation/components/SoftInput";
import SoftButton from "presentation/components/SoftButton";

// Authentication layout components
import BasicLayout from "presentation/scenes/authentication/components/BasicLayout";
import Socials from "presentation/scenes/authentication/components/Socials";
import Separator from "presentation/scenes/authentication/components/Separator";

// Images
import curved6 from "presentation/assets/images/curved-images/curved14.jpg";
import { useTranslate } from "domain/hooks/useTranslate";
import { useAuth } from "domain/hooks/useAuth";
import { initialUser } from "data/context/authContext";
import { useHandleFirebaseError } from "domain/hooks/useHandleFirebaseError";

function SignUp() {
  const { signUp } = useAuth();
  const { translate } = useTranslate();
  const { showAlertByErrorsFirebaseRequest } = useHandleFirebaseError();
  const [user, setUser] = useState(initialUser);
  const [validateForms, setValidateForm] = useState({
    name: {
      isValid: true
    },
    lastName: {
      isValid: true
    },
    email: {
      isValid: true
    },
    confirmEmail: {
      isValid: true
    },
    password: {
      isValid: true,
    },
    confirmPassword: {
      isValid: true
    }
  });
  const [agreement, setAgremment] = useState(true);

  const handleSetAgremment = () => setAgremment(!agreement);

  const handleChangeInputs = (value, key) => {
    validateForms[key].isValid = value.trim() !== '';
    setValidateForm({...validateForms});
    setUser({...user, [key]: value});
  }

  const handlerSignUp = async () => {
    Object.keys(validateForms).map(key => handleChangeInputs(user[key], key));
    if (Object.keys(validateForms).map(key => validateForms[key].isValid).includes(false)) return;

    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(user.email)) {
      validateForms.email.isValid = false;
      setValidateForm({...validateForms});
    }

    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(user.confirmEmail)) {
      validateForms.confirmEmail.isValid = false;
      setValidateForm({...validateForms});
    }

    if (user.email !== user.confirmEmail) {
      validateForms.email.isValid = false;
      validateForms.confirmEmail.isValid = false;
      setValidateForm({...validateForms});
    }

    if (user.password !== user.confirmPassword) {
      validateForms.confirmPassword.isValid = false;
      validateForms.password.isValid = false;
      setValidateForm({...validateForms});
    }

    if (Object.keys(validateForms).map(key => validateForms[key].isValid).includes(false)) return;

    try { 
      await signUp(user);
    } catch (error) {
      showAlertByErrorsFirebaseRequest(error)
    }
    
  }

  return (
    <BasicLayout
      title={translate('signUpPage.welcome')}
      description={translate('signUpPage.useThisFormToCreateANewAccount')}
      image={curved6}
    >
      <Card>
        <SoftBox pt={3} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            {translate('register')}
          </SoftTypography>
        </SoftBox>
        <SoftBox pt={1} pb={3} px={3}>
          <SoftBox component="form" role="form">
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox mb={1}>
                <SoftBox ml={0.5}>
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    {translate('name')}
                  </SoftTypography>
                </SoftBox>
                <SoftInput placeholder={translate('name')} error={!validateForms.name.isValid} onChange={(e) => handleChangeInputs(e.target.value, 'name') } />
              </SoftBox>
              <SoftBox mb={1}>
                <SoftBox ml={0.5}>
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    {translate('lastName')}
                  </SoftTypography>
                </SoftBox>
                <SoftInput placeholder={translate('lastName')} error={!validateForms.lastName.isValid} onChange={(e) => handleChangeInputs(e.target.value, 'lastName')} />
              </SoftBox>
            </SoftBox>
            <SoftBox mb={1}>
              <SoftBox ml={0.5}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  {translate('email')}
                </SoftTypography>
              </SoftBox>
              <SoftInput type="email" placeholder={translate('email')} error={!validateForms.email.isValid} onChange={(e) => handleChangeInputs(e.target.value, 'email')} />
            </SoftBox>
            <SoftBox mb={1}>
              <SoftBox ml={0.5}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  {translate('confirmEmail')}
                </SoftTypography>
              </SoftBox>
              <SoftInput type="email" placeholder={translate('confirmEmail')} error={!validateForms.confirmEmail.isValid} onChange={(e) => handleChangeInputs(e.target.value, 'confirmEmail')} />
            </SoftBox>
            <SoftBox mb={1}>
              <SoftBox ml={0.5}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  {translate('password')}
                </SoftTypography>
              </SoftBox>
              <SoftInput type="password" placeholder={translate('password')} error={!validateForms.password.isValid} onChange={(e) => handleChangeInputs(e.target.value, 'password')} />
            </SoftBox>
            <SoftBox mb={1}>
              <SoftBox ml={0.5}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  {translate('confirmPassword')}
                </SoftTypography>
              </SoftBox>
              <SoftInput type="password" placeholder={translate('confirmPassword')} error={!validateForms.confirmPassword.isValid} onChange={(e) => handleChangeInputs(e.target.value, 'confirmPassword')} />
            </SoftBox>
            {/* <SoftBox display="flex" alignItems="center">
              <Checkbox checked={agreement} onChange={handleSetAgremment} />
              <SoftTypography
                variant="button"
                fontWeight="regular"
                onClick={handleSetAgremment}
                sx={{ cursor: "poiner", userSelect: "none" }}
              >
                &nbsp;&nbsp;{translate('iAgreeThe')}&nbsp;
              </SoftTypography>
              <SoftTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                textGradient
              >
                {translate('termsAndConditions')}
              </SoftTypography>
            </SoftBox> */}
            <SoftBox mt={3} mb={1}>
              <SoftButton variant="gradient" color="dark" fullWidth onClick={handlerSignUp}>
                {translate('signUp')}
              </SoftButton>
            </SoftBox>
            {/* <Separator />
            <SoftBox mb={2}>
              <Socials />
            </SoftBox> */}
            <SoftBox mt={2} textAlign="center">
              <SoftTypography variant="button" color="text" fontWeight="regular">
                {translate('alreadyHaveAnAccount')}&nbsp;
                <SoftTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="dark"
                  fontWeight="bold"
                  textGradient
                >
                  {translate('signIn')}
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Card>
    </BasicLayout>
  );
}

export default SignUp;
