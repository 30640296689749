import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "domain/hooks/useAuth";

const UseRouteOnlyWithAuth = () => {
  const { uid } = useAuth();
  if (!uid) return <Navigate to="/authentication/sign-in" />;
  return <Outlet />;
};

export default UseRouteOnlyWithAuth;