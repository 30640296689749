import { useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SoftBox from "presentation/components/SoftBox";
import SoftTypography from "presentation/components/SoftTypography";
import SoftInput from "presentation/components/SoftInput";
import SoftButton from "presentation/components/SoftButton";

// Authentication layout components
import CoverLayout from "presentation/scenes/authentication/components/CoverLayout";
import Socials from "presentation/scenes/authentication/components/Socials";
import Separator from "presentation/scenes/authentication/components/Separator";

// Images
import curved9 from "presentation/assets/images/curved-images/curved-6.jpg";
import { useTranslate } from "domain/hooks/useTranslate";
import { trim } from "stylis";
import { useAuth } from "domain/hooks/useAuth";
import { useHandleFirebaseError } from "domain/hooks/useHandleFirebaseError";

function SignIn() {
  const { signIn } = useAuth();
  const { showAlertByErrorsFirebaseRequest } = useHandleFirebaseError();
  const { translate } = useTranslate();
  const [ credential, setCredential ] = useState({
    email: '',
    password: ''
  });
  const [validateForms, setValidateForm] = useState({
    email: {
      isValid: true
    },
    password: {
      isValid: true
    }
  });
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleChangeInputs = (value, key) => {
    validateForms[key].isValid = value.trim() !== '';
    setValidateForm({...validateForms});
    setCredential({...credential, [key]: value});
  }

  const handlerSignIn = async () => {
    Object.keys(validateForms).map(key => handleChangeInputs(credential[key], key));
    if (Object.keys(validateForms).map(key => validateForms[key].isValid).includes(false)) return;
  
    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(credential.email)) {
      validateForms.email.isValid = false;
      setValidateForm({...validateForms});
    }

    if (Object.keys(validateForms).map(key => validateForms[key].isValid).includes(false)) return;

    

    try { 
      await signIn(credential.email, credential.password);
    } catch (error) {
      showAlertByErrorsFirebaseRequest(error)
    }
  }

  return (
    <CoverLayout
      title={translate('signInPage.welcomeBack')}
      description={translate('signInPage.enterYourEmailAndPassword')}
      image={curved9}
    >
      <SoftBox component="form" role="form">
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              {translate('email')}
            </SoftTypography>
          </SoftBox>
          <SoftInput type="email" placeholder={translate('email')} error={!validateForms.email.isValid} onChange={(e) => handleChangeInputs(e.target.value, 'email')} />
        </SoftBox>
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              {translate('password')}
            </SoftTypography>
          </SoftBox>
          <SoftInput type="password" placeholder={translate('password')} error={!validateForms.password.isValid} onChange={(e) => handleChangeInputs(e.target.value, 'password')} />
        </SoftBox>
        {/* <SoftBox display="flex" alignItems="center">
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <SoftTypography
            variant="button"
            fontWeight="regular"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;{translate('rememberMe')}
          </SoftTypography>
        </SoftBox> */}
        {/* <Separator />
        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            {translate('signInWith')}
          </SoftTypography>
        </SoftBox>
        <SoftBox mb={2}>
          <Socials />
        </SoftBox> */}
        <SoftBox mt={4} mb={1}>
          <SoftButton variant="gradient" color="info" fullWidth onClick={handlerSignIn}>
            {translate('signIn')}
          </SoftButton>
        </SoftBox>
        <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            { translate('dontHaveAnAccount') + " "}
            <SoftTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              {translate('signUp')}
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </CoverLayout>
  );
}

export default SignIn;
