import { setShareToast, useShareController } from 'data/context/shareContext';

export const useToast = () => {
    const [ shareController, shareDispatch ] = useShareController();

    const showToast = (type, message) => {
        setShareToast(shareDispatch, { open: true, type, message });
    }

    const hideToast = () => {
        setShareToast(shareDispatch, { open: false, type: '', message: '' });
    }

    return {
        showToast,
        hideToast
    }
};