import { createContext, useContext, useReducer, useMemo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

const initialUser = {
    fullName: '',
    name: '',
    lastName: '',
    movil: '',
    location: '',
    email: '',
    confirmEmail: '',
    password: '',
    confirmPassword: '',
    urlPerfilImage: '',
    profesion: '',
    info: '',
    social: [],
    signInMethod: 'email/password',
    userId: null,
};

const Auth = createContext(null);
Auth.displayName = 'AuthContext';

const reducer = (state, action) => {
    switch (action.type) {
        case "AUTH_UID": {
            return { ...state, uid: action.value };
        }
        case "AUTH_USER": {
            return { ...state, user: action.value };
        }
    }
}

const AuthControllerProvider = ({children}) => {
    const initialState = {
        user: initialUser,
        uid: null
    }

    const [authController, authDispatch] = useReducer(reducer, initialState);

    const value = useMemo(() => [authController, authDispatch], [authController, authDispatch]);

    return <Auth.Provider value={value}>{children}</Auth.Provider>;
}

const useAuthController = () => {
    const context = useContext(Auth);
  
    if (!context) {
      throw new Error("useAuthController should be used inside the AuthControllerProvider.");
    }
  
    return context;
}

// Typechecking props for the SoftUIControllerProvider
AuthControllerProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

const setAuthUid = (dispatch, value) => dispatch({ type: "AUTH_UID", value });
const setAuthUser = (dispatch, value) => dispatch({ type: "AUTH_USER", value });

export {
    AuthControllerProvider,
    initialUser,
    useAuthController,
    setAuthUid,
    setAuthUser,
}