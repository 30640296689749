// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "presentation/components/SoftBox";
import SoftTypography from "presentation/components/SoftTypography";

// Soft UI Dashboard React base styles
import colors from "presentation/assets/theme/base/colors";
import typography from "presentation/assets/theme/base/typography";

function ProfileInfoCard({ title, description, info, socialInfo, action }) {
  const { socialMediaColors } = colors;
  const { size } = typography;

  // Render the card info items
  const renderItems = info.map((element, key) => (
    <SoftBox key={key} display="flex" py={1} pr={2}>
      { element.value && (
        <>
          <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize">
            {element.label}:&nbsp;
          </SoftTypography>
          <SoftTypography variant="button" fontWeight="regular" color="text">
            {element.value}
          </SoftTypography>
        </>
      )}
    </SoftBox>
  ));

  // Render the card social media icons
  const renderSocial = socialInfo.social.map(({ link, icon, color }) => (
    <SoftBox
      key={color}
      component="a"
      href={link}
      target="_blank"
      rel="noreferrer"
      fontSize={size.lg}
      color={socialMediaColors[color].main}
      pr={1}
      pl={0.5}
      lineHeight={1}
    >
      {icon}
    </SoftBox>
  ));

  const handleEditProfile = () => {
    console.log("TODO, EDIT PROFILE")
  }

  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </SoftTypography>
        <SoftTypography component={Link} to={action.route} variant="body2" color="secondary">
          <Tooltip title={action.tooltip} placement="top" onClick={handleEditProfile}>
            <Icon>edit</Icon>
          </Tooltip>
        </SoftTypography>
      </SoftBox>
      <SoftBox p={2}>
        <SoftBox mb={2} lineHeight={1}>
          <SoftTypography variant="button" color="text" fontWeight="regular">
            {description}
          </SoftTypography>
        </SoftBox>
        <SoftBox opacity={0.3}>
          <Divider />
        </SoftBox>
        <SoftBox>
          {renderItems}
          {socialInfo.length > 0 && <SoftBox display="flex" py={1} pr={2}>
            <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize">
              {socialInfo.label}:&nbsp;
            </SoftTypography>
            {renderSocial}
          </SoftBox>}
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

// Typechecking props for the ProfileInfoCard
ProfileInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  info: PropTypes.objectOf(PropTypes.array).isRequired,
  socialInfo: PropTypes.objectOf(PropTypes.object).isRequired,
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProfileInfoCard;
