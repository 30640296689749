// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAz_y0jyE-O_AZdBkP-BFPVxBys9UdmE1k",
  authDomain: "softindev-company.firebaseapp.com",
  projectId: "softindev-company",
  storageBucket: "softindev-company.appspot.com",
  messagingSenderId: "312810547754",
  appId: "1:312810547754:web:d8aff12b90e72717b33153",
  measurementId: "G-JG5HW072C7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const fireAuth = getAuth(app);
const fireStore = getFirestore(app);
const fireAuthGoogle = new GoogleAuthProvider();
export {
    app,
    analytics,
    fireAuth,
    fireAuthGoogle,
    fireStore
}
