import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import usEN from './lang/US-en.json';
import coES from './lang/CO-es.json';

const resources = {
    'usEN': {
        translation: usEN
    },
    'coES': {
        translation: coES
    }
}

i18next.use(initReactI18next)
.init({
    resources,
    lng: 'coES' // Default Languague
});

export default i18next;