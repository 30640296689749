import { createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth";
import { fireAuth, fireStore } from 'data/firebase';
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";

const getAuthUser = (setUserId) => {
    onAuthStateChanged(fireAuth, setUserId);
}

const getUserByUidAuth = async (uid) => {
    const userDoc = query(collection(fireStore, 'users'), where('userId', '==', uid));
    const userSnap = await getDocs(userDoc);

    let _user = null;
    userSnap.forEach(user => {
        const userData = user.data();
        if (userData.userId === uid) _user = userData;
    });

    if (!_user) {
        console.log("no exist data");
    }

    return _user;
}

const signInUser = async (email, password) => {
    const userCredential = await signInWithEmailAndPassword(fireAuth, email, password);
    const  { user: {
        uid,
        accessToken
    }} = userCredential;

    const _user = await getUserByUidAuth(uid);
    return {
        accessToken,
        user: _user,
    }
}

const singOutUser = async () => signOut(fireAuth);

const createUser = async (user) => {
    const userCredential = await createUserWithEmailAndPassword(fireAuth, user.email, user.password);
    const { user: {
        uid,
        accessToken
    }} = userCredential;

    const registerUser = {
        ...user,
        fullName: `${user.name || ''} ${user.lastName || ''}`,
        userId: uid,
    }
    delete registerUser.confirmEmail;
    delete registerUser.password;
    delete registerUser.confirmPassword;

    await addDoc(collection(fireStore, 'users'), registerUser);

    const _user = await getUserByUidAuth(uid);
    return {
        accessToken,
        user: _user,
    }
}

export { getAuthUser, getUserByUidAuth, signInUser, singOutUser, createUser }
