// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

// Soft UI Dashboard React components
import SoftBox from "presentation/components/SoftBox";
import SoftTypography from "presentation/components/SoftTypography";
import { useTranslate } from "domain/hooks/useTranslate";

function Footer({ links }) {
  const { translate } = useTranslate();
  return (
    <SoftBox component="footer" bgColor="dark" py={6}>
      <Grid item xs={12} lg={8} sx={{ textAlign: "center" }}>
        <SoftBox display="flex"  justifyContent="center" flexWrap="wrap" mb={3}>
          <SoftBox>
            <SoftTypography component="a" href={`mailto:${translate('appWeb.contactinfo.email')}`} variant="body2" color="white">
              {translate('appWeb.contactinfo.email')}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </Grid>
      <Grid container justifyContent="center">
        {links.length && <Grid item xs={10} lg={8}>
          <SoftBox display="flex" justifyContent="center" flexWrap="wrap" mb={3}>
            {
              links.map((link, key) => (
                <SoftBox key={key}>
                  <SoftTypography component="a" href={link.route} variant="body2" color="white">
                    {key !== 0 && `|`}
                    &nbsp;&nbsp;{link.name}&nbsp;&nbsp;
                  </SoftTypography>
                </SoftBox>
              ))
            }
          </SoftBox>
        </Grid> }
        <Grid item xs={12} lg={8}>
          <SoftBox display="flex" justifyContent="center" mt={1} mb={3}>
            <SoftTypography component="a" href={translate('appWeb.social.facebook')} target="_blank" variant="body2" color="white">
              <SoftBox mr={3} color="white">
                <FacebookIcon fontSize="small" />
              </SoftBox>
            </SoftTypography>
            {/* <SoftTypography component="a" href={translate('appWeb.social.twitter')} target="_blank" variant="body2" color="white">
              <SoftBox mr={3} color="white">
                <TwitterIcon fontSize="small" />
              </SoftBox>
            </SoftTypography> */}
            <SoftTypography component="a" href={translate('appWeb.social.instagram')} target="_blank" variant="body2" color="white">
              <SoftBox mr={3} color="white">
                <InstagramIcon fontSize="small" />
              </SoftBox>
            </SoftTypography>
            {/* <SoftTypography component="a" href={translate('appWeb.social.pinteres')} variant="body2" color="white">
              <SoftBox mr={3} color="white">
                <PinterestIcon fontSize="small" />
              </SoftBox>
            </SoftTypography> */}
            <SoftTypography component="a" href={translate('appWeb.social.github')} target="_blank" variant="body2" color="white">
              <SoftBox mr={3} color="white">
                <GitHubIcon fontSize="small" />
              </SoftBox>
            </SoftTypography>
            <SoftTypography component="a" href={translate('appWeb.social.linkedin')} target="_blank" variant="body2" color="white">
              <SoftBox mr={3} color="white">
                <LinkedInIcon fontSize="small" />
              </SoftBox>
            </SoftTypography>
          </SoftBox>
        </Grid>
        <Grid item xs={12} lg={8} sx={{ textAlign: "center" }}>
          <SoftTypography variant="body2" color="white">
          &copy;{new Date().getFullYear()} {translate('copyright')}, &nbsp;{translate('allRightsReserved')}.
          </SoftTypography>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

// Setting default values for the props of Footer
Footer.defaultProps = {
  links: [],
};

// Typechecking props for the CoverLayout
Footer.propTypes = {
  links: PropTypes.array,
};

export default Footer;
